.wrapper
{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100vh;
}

.isModal
{
	height: auto;
}

.inner
{
	max-width: 30rem;
	padding: 1rem;
	text-align: center;
}

.title
{
	margin-bottom: 2rem;
}

.close
{
	position: absolute;
	top: -2rem;
	right: -2rem;
	font-size: 2rem;
	color: white;
	cursor: pointer;
	opacity: 0.8;

	&:hover
	{
		opacity: 1;
	}
}
