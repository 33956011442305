$color-accent: #ce0f0f;
$color-body-background: #FFF;
$color-basic-background: #EEE;
$color-basic-accent: #F7F7F7;
$header-size: 60px;

$color-base-on: #FFBC1A;
$color-ball: #179727;
$color-strike: #a80000;
$color-out: #a80000;
